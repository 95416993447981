.pay-container {
  background: #FFF;
}
.pay-title {
  font-size: 0.37333333rem;
  color: #333;
  height: 1.6rem;
  line-height: 1.4rem;
  margin: 0 .4rem;
  border-bottom: .02rem solid #e0e0e0;
  padding-top: .2rem;
  font-weight: 600;
}
.pay-title span {
  float: right;
  color: #333;
  font-size: 0.48rem;
  font-weight: normal;
}
.pay-money {
  text-align: right;
  height: 2rem;
  line-height: 2rem;
  margin: 0 .4rem;
  font-size: 0.37333333rem;
}
.pay-money i {
  font-size: 0.69333333rem;
  margin: 0 .1rem 0 .18rem;
  line-height: 2rem;
  color: #333;
}
.pay-money span {
  font-size: 0.69333333rem;
  line-height: 2rem;
  color: #333;
}
.pay-type .hd {
  height: 1.2rem;
  display: flex;
  align-items: center;
  font-size: 0.32rem;
  color: #666;
  background: #F6F6F6;
  padding: 0 .4rem;
}
.pay-type .item {
  display: flex;
  position: relative;
  height: 1.2rem;
  align-items: center;
  font-size: 0.37333333rem;
  padding: 0 .4rem 0 1.2rem;
  border-bottom: .02rem solid #e0e0e0;
}
.pay-type .item:last-child {
  border: none;
}
.pay-type .item img {
  position: absolute;
  width: .6rem;
  top: 50%;
  margin-top: -0.3rem;
  left: .4rem;
}
.pay-type .item i {
  position: absolute;
  font-size: 0.58666667rem;
  color: #f1f1f1;
  right: .4rem;
  top: 50%;
  margin-top: -0.34rem;
}
.pay-type .item.active i {
  color: #1DA1F2;
}
.pay-submit {
  display: flex;
  justify-content: center;
  padding: .6rem .6rem .2rem;
}
.pay-submit a {
  display: block;
  height: 1.2rem;
  line-height: 1.2rem;
  font-size: 0.37333333rem;
  color: #FFF;
  background: #1DA1F2;
  border-radius: .12rem;
  width: 100%;
  text-align: center;
}
.pay-desc {
  padding: .4rem;
}
.pay-desc h3 {
  font-size: 0.37333333rem;
  margin-bottom: .2rem;
  font-weight: 600;
  color: #333;
}
.pay-desc p {
  font-size: 0.32rem;
  color: #666;
  line-height: .52rem;
}
.pay-desc p a {
  color: #1DA1F2;
  font-size: 0.37333333rem;
}
