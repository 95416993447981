.pay                {
    &-container     {background: #FFF;}
    &-title         {.fs(14);color: #333;height: 1.6rem;line-height: 1.4rem;margin: 0 .4rem;border-bottom: .02rem solid #e0e0e0;padding-top: .2rem;font-weight: 600;
        span        {float: right;color: #333;.fs(18);font-weight: normal;}
    }
    &-money         {text-align: right;height: 2rem;line-height: 2rem;margin: 0 .4rem;.fs(14);
        i           {.fs(26);margin: 0 .1rem 0 .18rem;line-height:2rem;color: #333;}
        span        {.fs(26);line-height:2rem;color: #333;}
    }

    &-type          {
        .hd         {height: 1.2rem;display: flex;align-items: center;.fs(12);color: #666;background: #F6F6F6;padding: 0 .4rem;}
        .item       {display: flex;position: relative;height: 1.2rem;align-items: center;.fs(14);padding: 0 .4rem 0 1.2rem;border-bottom: .02rem solid #e0e0e0;
            &:last-child {border:none;}
            img     {position: absolute;width: .6rem;top:50%;margin-top: -.3rem;left: .4rem;}
            i       {position: absolute;.fs(22);color: #f1f1f1;right: .4rem;top: 50%;margin-top: -.34rem;}
            &.active{
                i   {color: #1DA1F2;}
            }
        }
    }

    &-submit        {display: flex;justify-content: center;padding: .6rem .6rem .2rem;
        a           {display: block;height: 1.2rem;line-height: 1.2rem;color: #FFF;.fs(14);color: #FFF;background: #1DA1F2;border-radius: .12rem;width: 100%;text-align: center;}
    }

    &-desc           {padding: .4rem;
        h3           {.fs(14);margin-bottom: .2rem;font-weight: 600;color: #333;}
        p            {.fs(12);color: #666;line-height: .52rem;
            a        {color: #1DA1F2;.fs(14);}
        }
    }
}